// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-linkovi-tsx": () => import("./../../../src/pages/linkovi.tsx" /* webpackChunkName: "component---src-pages-linkovi-tsx" */),
  "component---src-pages-oautorima-tsx": () => import("./../../../src/pages/oautorima.tsx" /* webpackChunkName: "component---src-pages-oautorima-tsx" */),
  "component---src-pages-oknjizi-tsx": () => import("./../../../src/pages/oknjizi.tsx" /* webpackChunkName: "component---src-pages-oknjizi-tsx" */)
}

